import React, { useEffect, useState } from 'react';

// A basic "modal" component that only appears when `show` is true
// This is just a simple example. You can replace it with a more robust 
// component from a library like Material-UI, Ant Design, etc.
const Modal = ({ show, onClose, children }) => {
  if (!show) return null;

  return (
    <div 
      style={{
        position: 'fixed',
        top: 0, left: 0, right: 0, bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onClick={onClose}
    >
      <div 
        style={{
          backgroundColor: '#fff',
          padding: '2rem',
          borderRadius: '4px',
          maxWidth: '500px',
          width: '100%',
        }}
        // Stop clicks on the modal from closing
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
};

const OrdersPage = ({token}) => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Replace with your actual API endpoint and auth token
  const apiEndpoint = `https://api.staging.waitwhiz.in/business/orders?auth_token=${token}`;

  useEffect(() => {
    // Don't fetch if token is not available
    if (!token) return;

    const fetchOrders = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(apiEndpoint);
        const data = await response.json();

        if (data.success) {
          setOrders(data.data);
        } else {
          setError('Failed to fetch orders: ' + data.message);
        }
      } catch (err) {
        setError('Error fetching orders: ' + err.message);
      }
      setLoading(false);
    };

    fetchOrders();
  }, [apiEndpoint, token]);

  // Handler for row click -> open modal
  const openModalForOrder = (order) => {
    setSelectedOrder(order);
  };

  // Handler for closing the modal
  const closeModal = () => {
    setSelectedOrder(null);
  };

  // Handlers for Accept/Reject (replace with your logic)
  const handleAccept = () => {
    console.log('Accepting order:', selectedOrder.id);
    // Example: Make an API call to update order status
    closeModal();
  };

  const handleReject = () => {
    console.log('Rejecting order:', selectedOrder.id);
    // Example: Make an API call to update order status
    closeModal();
  };

  if (loading) {
    return <div>Loading orders…</div>;
  }

  if (error) {
    return <div style={{ color: 'red' }}>{error}</div>;
  }

  return (
    <div style={{ padding: '1rem' }}>
      <h2>Orders</h2>
      {orders.length === 0 && <div>No orders found.</div>}

      {/* Table of Orders */}
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={{ borderBottom: '1px solid #ccc', padding: '0.5rem' }}>Order Reference</th>
            <th style={{ borderBottom: '1px solid #ccc', padding: '0.5rem' }}>Status</th>
            <th style={{ borderBottom: '1px solid #ccc', padding: '0.5rem' }}>Total Amount</th>
            <th style={{ borderBottom: '1px solid #ccc', padding: '0.5rem' }}>Created At</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => {
            const createdDate = new Date(order.created_at);
            const formattedDate = createdDate.toLocaleString('en-GB'); 
            // 'en-GB' yields DD/MM/YYYY, HH:MM:SS

            return (
              <tr
                key={order.id}
                style={{ cursor: 'pointer' }}
                onClick={() => openModalForOrder(order)}
              >
                <td style={{ padding: '0.5rem', borderBottom: '1px solid #ccc' }}>
                  {order.reference_id}
                </td>
                <td style={{ padding: '0.5rem', borderBottom: '1px solid #ccc' }}>
                  {order.status}
                </td>
                <td style={{ padding: '0.5rem', borderBottom: '1px solid #ccc' }}>
                  {order.total_amount} {order.currency}
                </td>
                <td style={{ padding: '0.5rem', borderBottom: '1px solid #ccc' }}>
                  {formattedDate}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Modal for Selected Order */}
      <Modal show={!!selectedOrder} onClose={closeModal}>
        {selectedOrder && (
          <div>
            <h3>Order Details</h3>
            <p><strong>Reference:</strong> {selectedOrder.reference_id}</p>
            <p><strong>Status:</strong> {selectedOrder.status}</p>
            <p><strong>Total Amount:</strong> {selectedOrder.total_amount} {selectedOrder.currency}</p>
            <p>
              <strong>Created At:</strong>{' '}
              {new Date(selectedOrder.created_at).toLocaleString('en-GB')}
            </p>
            
            <h4>Product Items</h4>
            {selectedOrder.product_items &&
             selectedOrder.product_items.product_items && (
              <ul>
                {selectedOrder.product_items.product_items.map((item, idx) => (
                  <li key={idx}>
                    {/* Placeholder: using product_retailer_id as "name" */}
                    <strong>Name (placeholder):</strong> {item.product_retailer_id} <br/>
                    <strong>Quantity:</strong> {item.quantity} <br/>
                    <strong>Price:</strong> {item.item_price} {item.currency}
                  </li>
                ))}
              </ul>
            )}

            <div style={{ marginTop: '1rem' }}>
              <button onClick={handleAccept}>Accept</button>
              <button onClick={handleReject} style={{ marginLeft: '1rem' }}>Reject</button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default OrdersPage;
