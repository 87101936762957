import React, { useEffect, useState } from 'react';
import ChatList from './ChatList';
import ChatWindow from './ChatWindow';
import { useLocation } from 'react-router-dom';
import './ChatList.css'; // separate CSS file

const ChatPage = ({ token }) => {
  const location = useLocation();
  const [selectedChat, setSelectedChat] = useState(null);
  // const [contact, setContact] = useState(null);
  const [contact, setContact] = useState(() => {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get('contact');
  });
  

  const handleChatClick = (chat) => {
    setSelectedChat(chat);
  };

  // useEffect(() => {
  //   console.log('location.search:', location.search);
  //   const queryParams = new URLSearchParams(location.search);
  //   const contactParam = queryParams.get('contact');
  //   console.log('contactParam:', contactParam);
  //   setContact(contactParam);
  // }, [location.search]);
  

  return (
    <div className="whatsapp-page-container">
      <ChatList onChatClick={handleChatClick} selectedChat={selectedChat} phone_number_id={contact} />
      <ChatWindow chat={selectedChat} token={token} phone_number_id={contact} />
    </div>
  );
};

export default ChatPage;
