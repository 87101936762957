import React, { useState, useEffect } from 'react';
import Modal from './Modal';
import UserForm from './UserForm';
import ReactDOM from 'react-dom'; 
import ConfirmEntryModal from './ConfirmEntryModal';
import WaitingTimeEdit from './WaitingTimeEdit';
import AddRemarkModal from './AddRemarkModal';
import QueueService from './QueueService';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import CustomerHistoryModal from './CustomerHistoryModal';
import MoveToQueueModal from './ConfirmMoveToQueueModal';
import BusinessTablesModal from './BusinessTablesModal';
import { FaEllipsisV, FaPlus, FaArrowRight, FaToggleOn, FaToggleOff, FaPhoneAlt, FaEdit} from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import './QueueManager.css';
import Message from "./Message";
import {onMessage } from 'firebase/messaging'
import { messaging } from "../firebase/firebaseConfig";
import NumberModal from './NumberModal'; 
import CallModal from './CallModal';
import AICallModal from './AICallModal';
import ApproveReservationModal from './ApproveReservationModal';
import RejectReservationModal from './RejectReservationModal';
import WhatsAppChatModal from './WhatsAppChatModal';




const QueueTable = ({ business_id, business_user_id, token, handleLogout }) => {
  const [users, setUsers] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [modalData, setModalData] = useState({
    first_table_vacant_time:0,
    average_waiting_time: 0,
    max_waiting_number: 0,
    min_time_diff_between_entries: 0,
    max_time_diff_between_entries: 100,
    queue_id: null,
  });
  const [currentUser, setCurrentUser] = useState(null);
  const [queue_info, setQueueInfo] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [showWaitingTimeEdit, setShowWaitingTimeEdit] = useState(false);
  const [showChatModal, setShowChatModal] = useState(false); 
  const [currentQueue, setCurrentQueue] = useState(null);
  const [currentQueueType, setCurrentQueueType] = useState(null);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showMoveToQueueModal, setShowMoveToQueueModal] = useState(false);
  const [customer_name, setCustomerName] = useState(false);
  const [showCallModal, setCallModal] = useState({
    isOpen: false,
    userId: null,
    userName: null
  });
  const [showAICallModal, setAICallModal] = useState({
    isOpen: false,
    phoneNumber: null,
    userName: null,
    mapping_id: null
  });
  const [showEntryModal, setShowEntryModal] = useState(false);
  const [TablesModal, setTablesModal] = useState(false);
  const [queue_auto, setQueueAutoSelect] = useState(false);
  const [showRemarkModal, setShowRemarkModal] = useState(false);
  const [entryUserId, setEntryUserId] = useState(null);
  const [remarkUserId, setRemarkUserId] = useState(null);
  const [message, setMessage] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showNumberModal, setShowNumberModal] = useState(false);
  const dropdownRef = React.useRef(null);
  const [tables, setTables] = useState(null);
  const [showCustomerHistoryModal, setShowCustomerHistoryModal] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [showApproveReservationModal, setShowApproveReservationModal] = useState(false);
  const [showRejectReservationModal, setShowRejectReservationModal] = useState(false);
  const [selectedUserForApproval, setSelectedUserForApproval] = useState(null);
  const [business_type, setBusinessType] = useState("");

    const toggleDropdown = (index) => {
        setOpenDropdownIndex(openDropdownIndex === index ? null : index);
      };
    
      let counter = 0;
      onMessage(messaging, (payload) => {
        counter = counter + 1;
        console.log('printing message');
        console.log(payload);
        console.log('counter');
        console.log(payload.title);
        if (payload.notification.title === 'user_enqueued'){
          updateNotificationPanel([{"body": `${counter} new User(s) added`}]);
        }
        else if (payload.notification.title === 'call_status') {
          updateUserCallStatus(JSON.parse(payload.notification.body));
        }
        else if(payload.notification.title === 'mapping_updated') {
          const body = JSON.parse(payload.notification.body)
          if(body.status === 'entered') {
            updateNotificationPanel([{"body": `User(s) marked as Entered`}]);
        }
        else if(body.status === 'inactive'){
          updateNotificationPanel([{"body": `User(s) Deleted`}]);
        }
        else if(body.table_ready === true) {
          updateUser(body)
        }
        else if (body.entity_id) {
          setUsers(prevUsers => 
            prevUsers.map(user => {
              if (user.id === parseInt(body.entity_id)) {
                // Ensure whatsapp_messages is initialized as an array
                const updatedWhatsappMessages = [...(user.whatsapp_messages || []), body];
                
                // Return the updated user object
                return {
                  ...user,
                  whatsapp_messages: updatedWhatsappMessages
                };
              }
              return user; // Return the user unchanged if the id doesn't match
            })
          );
        }
      }
      else if(payload.notification.title === 'table_updated'){
        fetchTables(token);
        const body = JSON.parse(payload.notification.body)
        updateNotificationPanel([{"body": `Table ${body.name} status changed to ${body.status}`}]);
      }
      else if(payload.notification.title === 'petpooja_status'){
        const body = JSON.parse(payload.notification.body)
        if(body.status === 'success'){
          setMessage({ type: 'success', text: body.message || 'Petpooja Status updated' });
          setTimeout(() => setMessage(null), 5000);
        }
        else if(body.status === 'failed'){
          setMessage({ type: 'error', text: body.message || 'Petpooja Status update failed' });
          setTimeout(() => setMessage(null), 5000);
        }
      }
      });



    // Load cached tables on component mount
    useEffect(() => {
      const cachedTables = localStorage.getItem('cachedTables');
      if (cachedTables) {
        setTables(JSON.parse(cachedTables));
      }
    }, []);

    const fetchTables = async (token) => {
      if (!navigator.onLine) {
        console.warn('Offline: Loading cached tables.');
        const cached = localStorage.getItem('cachedTables');
        if (cached) {
          setTables(JSON.parse(cached));
          console.info('Loaded tables from cache.');
        } else {
          console.warn('No cached tables available.');
          setTables([]);
        }
        return;
      }
      try {
        const res = await QueueService.fetchTablesLite(token);
        if (res && res.length > 0) {
          setTables(res);
          // Cache the fetched tables
          localStorage.setItem('cachedTables', JSON.stringify(res));
        }
      } catch (error) {
        console.error('Error fetching tables from backend:', error);
        // Attempt to load tables from cache if fetch fails
        const cached = localStorage.getItem('cachedTables');
        if (cached) {
          setTables(JSON.parse(cached));
          console.info('Loaded tables from cache.');
        } else {
          console.warn('No cached tables available.');
          setTables([]);
        }
      }
    };

    function updateUserCallStatus(newCallData) {
      setUsers(prevUsers =>
        prevUsers.map(user => {
          if (user.id === newCallData.entity_id) {
            const updatedCalls = user.calls.map(call =>
              call.id === newCallData.id ? { ...call, ...newCallData } : call
            );
            const callExists = updatedCalls.some(call => call.id === newCallData.id);
            const newCalls = callExists ? updatedCalls : [newCallData, ...updatedCalls];
            return { ...user, calls: newCalls };
          }
          return user;
        })
      );
    }

    function updateUser(newUserData) {
      setUsers(prevUsers =>
        prevUsers.map(user =>
          user.id === newUserData.id ? { ...user, ...newUserData } : user
        )
      );
    }

    const formatNoteTime = (noteCreatedAt) => {
      const utcDate = new Date(noteCreatedAt);
      const offsetInMinutes = 5 * 60 + 30;
      const istDate = new Date(utcDate.getTime() + offsetInMinutes * 60000);
      return istDate.toLocaleTimeString('en-IN', {
        hour12: true,
        hour: '2-digit',
        minute: '2-digit'
      });
    };

    const handleCustomerClick = (customerId) => {
      setSelectedCustomerId(customerId);
      setShowCustomerHistoryModal(true);
    };

    function updateNotificationPanel(notifications) {
      const notificationPanel = document.getElementById('notification-panel');
      notificationPanel.style.display = notifications.length === 0 ? 'none' : 'block';
      notificationPanel.innerHTML = ''; // Clear existing notifications
      notifications.forEach((notification, index) => {
        const container = document.createElement('div');
        container.setAttribute('id', `notification-${index}`);
        const root = ReactDOM.createRoot(container);
        root.render(
          <Message
            notification={notification}
            onClick={() => {
              fetchAllUsers(business_id, business_user_id);
              fetchTables(token);
              root.unmount();
              notificationPanel.removeChild(container);
            }}
          />
        );
        notificationPanel.appendChild(container);
      });
    }

    const fetchBusinessInfo = async (business_id) => {
      if (!navigator.onLine) {
        console.warn('Offline: Loading cached business info.');
        const cached = localStorage.getItem('cachedBusinessInfo');
        if (cached) {
          const parsedCached = JSON.parse(cached);
          setQueueInfo(parsedCached.queue_info);
          setQueueAutoSelect(parsedCached.business_metadata.automatic_queue_selection);
          setBusinessType(parsedCached.business_type || "");
          console.info('Loaded business info from cache.');
        } else {
          console.warn('No cached business info available.');
        }
        return;
      }
      try {
        const response = await QueueService.GetBusinessInfo(business_id);
        if (response.queue_info && response.queue_info.length > 0) {
          setQueueInfo(response.queue_info);
          setQueueAutoSelect(response.business_metadata.automatic_queue_selection);
          setBusinessType(response.business_type || "");
          // Cache the fetched business info
          localStorage.setItem('cachedBusinessInfo', JSON.stringify(response));
        }
      } catch (error) {
        console.error('Error fetching business info:', error);
        // Load from cache if available
        const cached = localStorage.getItem('cachedBusinessInfo');
        if (cached) {
          const parsedCached = JSON.parse(cached);
          setQueueInfo(parsedCached.queue_info);
          setQueueAutoSelect(parsedCached.business_metadata.automatic_queue_selection);
          setBusinessType(parsedCached.business_type || "");
          console.info('Loaded business info from cache.');
        } else {
          console.warn('No cached business info available.');
        }
      }
    };

    // Load cached users on component mount
    useEffect(() => {
      const cached = localStorage.getItem('cachedUsers');
      if (cached) {
        setUsers(JSON.parse(cached));
      }
    }, []);

    const fetchAllUsers = async (business_id, business_user_id) => {
      if (!navigator.onLine) {
        console.warn('Offline: Loading cached users.');
        const cached = localStorage.getItem('cachedUsers');
        if (cached) {
          setUsers(JSON.parse(cached));
          console.info('Loaded users from cache.');
        } else {
          setUsers([]);
          console.warn('No cached users available.');
        }
        return;
      }
      try {
        const usersFromApi = await QueueService.FetchUsers(business_id, business_user_id, 'All', token);
        const updatedUsers = usersFromApi.map(user => ({
          ...user,
          // any additional processing if needed
        }));
        setUsers(updatedUsers);
        // Cache the fetched users
        localStorage.setItem('cachedUsers', JSON.stringify(updatedUsers));
      } catch (error) {
        console.error('Error fetching users from backend:', error);
        // Load from cache if available
        const cached = localStorage.getItem('cachedUsers');
        if (cached) {
          setUsers(JSON.parse(cached));
          console.info('Loaded users from cache.');
        } else {
          setUsers([]);
          console.warn('No cached users available.');
        }
      }
    };

    // Load current queue info from localStorage and update if needed
    useEffect(() => {
      const savedQueue = parseInt(localStorage.getItem('currentQueue'), 10);
      const savedQueueType = localStorage.getItem('currentQueueType');
      console.log(savedQueue);
      if (savedQueue) {
        setCurrentQueue(savedQueue);
        setCurrentQueueType(savedQueueType);
      } else if (queue_info.length > 0) {
        setCurrentQueue(queue_info[0].queue_id);
      }
    }, [queue_info]);

    // Fetch users and business info on mount (or when IDs change)
    useEffect(() => {
      fetchAllUsers(business_id, business_user_id);
      fetchBusinessInfo(business_id);
      fetchTables(token);
    }, [business_id, business_user_id]);

    
    
      useEffect(() => {
        function handleClickOutside(event) {
          if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setOpenDropdownIndex(null);
          }
        }
      
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);
    
    
      const handleCellClick = async (user) => {
        console.log(user);
      };
      

      const handleToggleStatus = async (queue_id, status) => {
        try {
          const newStatus = status === 'active' ? 'false' : 'true';
          const res = await QueueService.UpdateQueueStatus(token, queue_id, newStatus);
          setMessage({ type: 'success', text: res.message || 'Queue status updated successfully!' });
         
          const response = await QueueService.GetBusinessInfo(business_id);
          
          if (response.queue_info && response.queue_info.length > 0) {
            setQueueInfo(response.queue_info);
          }
        } catch (error) {
          console.error(error);
          setMessage({ type: 'error', text: error.message || 'Failed to update queue status.' });
    
        }
        finally {
          setTimeout(() => setMessage(null), 3000);
        }
    
      };
      
    
    
      
      const handleNumberClick = (first_table_vacant_time, average_waiting_time, max_waiting_number, min_time_diff_between_entries, max_time_diff_between_entries, queue_id) => {
        setModalData({
            first_table_vacant_time,
            average_waiting_time,
            max_waiting_number,
            min_time_diff_between_entries,
            max_time_diff_between_entries,
            queue_id
        });
        setShowNumberModal(true);
      };
      
      const handleNumberChange = (waiting_time, max_waiting_number, queue_id) => {
        setModalData({
          waiting_time,
          max_waiting_number,
          queue_id,
        });
      };
      
      const handleNumberSubmit = async (currentQueue, token, waiting_time, table_first_empty_time) => {
        try {
          await QueueService.UpdateQueueNumber(token, currentQueue, waiting_time, table_first_empty_time); // Call API to update the number
          setShowNumberModal(false);
          
        } catch (error) {
          console.error('Error updating number:', error);
        }
        fetchAllUsers(business_id, business_user_id);
        fetchBusinessInfo(business_id);
        
      };
      
      const formatDateTime = (dateTimeString) => {
        if (!dateTimeString) return '';
      
        const date = new Date(dateTimeString);
      
        // Convert the UTC time to IST by adding 5 hours and 30 minutes
        const offsetInMinutes = 5 * 60 + 30; // 5 hours and 30 minutes in minutes
        const istDate = new Date(date.getTime() + offsetInMinutes * 60000);
      
        // Get today's date for comparison (only year, month, and day)
        const today = new Date();
        const isToday =
          istDate.getDate() === today.getDate() &&
          istDate.getMonth() === today.getMonth() &&
          istDate.getFullYear() === today.getFullYear();
      
        // Format options
        const options = isToday
          ? { 
              hour: '2-digit', 
              minute: '2-digit', 
              hour12: true 
            }
          : { 
              day: 'numeric', 
              month: 'short', 
              hour: '2-digit', 
              minute: '2-digit', 
              hour12: true 
            };
      
        return istDate.toLocaleString('en-IN', {
          timeZone: 'Asia/Kolkata',
          ...options
        });
      };
      
    
      const makeIVRCall = async (user_id, user_name) => {
        setCallModal({
          isOpen: true,
          userId: user_id,
          userName: user_name
        });
         
      }
      
      const confirmCall = async (user_id, user_name) => {
        try{
        const res = await QueueService.makeCall(user_id, token); 
        // updateUserCallStatus(res);
        setMessage({ type: 'success', text: `Calling ${user_name}`});
        setTimeout(() => setMessage(null), 3000);
        }
        catch (error) {
          console.error(error);
          setMessage({ type: 'error', text: error.message || 'Failed to update queue status.' });
          setTimeout(() => setMessage(null), 3000);
    
      }
      setCallModal({
        isOpen: false,
        userId: null,
        userName: null
      });
    }
    
    
    
      const handleAddUser = () => {
        setCurrentUser(null);
        setShowForm(true);
      };
    
      const handleEditUser = (user) => {
        setCurrentUser(user);
        setShowForm(true);
        setOpenDropdownIndex(null);
      };
    
      const handleUserEntry = (userId, customer_name) => {
        setEntryUserId(userId);
        setCustomerName(customer_name);
        setShowEntryModal(true);
      };
    
      const add_note = (userId) => {
        setRemarkUserId(userId);
        setShowRemarkModal(true);
        setOpenDropdownIndex(null);
      };
    
    
      
      const confirmUserEntry = async (tableId, tableNo) => {
        try {
          await QueueService.MarkUserAsEntered(entryUserId, token, tableId, tableNo );
          setUsers(users.filter(user => user.id !== entryUserId));
          setShowEntryModal(false);
          setEntryUserId(null);
        } catch (error) {
          console.error('Error marking user as entered:', error);
        }
        fetchAllUsers(business_id, business_user_id);
        fetchBusinessInfo(business_id);
        
      };
    
    
        const confirmAddRemark = async (remark) => {
          try {
            await QueueService.add_note(token, remarkUserId, remark );
            setUsers(users.filter(user => user.id !== remarkUserId));
            setShowRemarkModal(false);
            setRemarkUserId(null);
          } catch (error) {
            console.error('Error marking user as entered:', error);
          }
          fetchAllUsers(business_id, business_user_id);
      };
    
      const handleDeleteUser = (userId, customer_name) => {
        setSelectedUser(userId);
        setShowDeleteModal(true);
        setCustomerName(customer_name);
        setOpenDropdownIndex(null);
      };

      const handleAiCall = (customer_name, phoneNumber, mapping_id) => {
        setAICallModal({
          isOpen: true,
          phoneNumber: phoneNumber,
          userName: customer_name,
          mapping_id: mapping_id
        });
        setOpenDropdownIndex(null);
      };

      

      const AiCall =  async (phone_number, user_name, mapping_id) => {
        try{
          phone_number = `91${phone_number}`
          const res = await QueueService.AiCall(phone_number, mapping_id); 
          setMessage({ type: 'success', text: `Calling ${user_name}`});
          setTimeout(() => setMessage(null), 3000);
          }
          catch (error) {
            console.error(error);
            setMessage({ type: 'error', text: error.message || 'Failed to Call' });
            setTimeout(() => setMessage(null), 3000);
        }
        setAICallModal({
          isOpen: false,
          phoneNumber: null,
          userName: null,
          mapping_id: null
        });
      };

      const handleMoveToQueue = (userId,customer_name) => {
        setSelectedUser(userId);
        setShowMoveToQueueModal(true);
        setCustomerName(customer_name);
        setOpenDropdownIndex(null);
      };
    
      const confirmDeleteUser = async (reason) => {
        try {
          await QueueService.DeleteUser(selectedUser, reason, business_id);
          setUsers(users.filter(user => user.id !== selectedUser));
          setShowDeleteModal(false);
        } catch (error) {
          console.error('Error deleting user:', error);
        }
        fetchAllUsers(business_id, business_user_id);
        fetchBusinessInfo(business_id);
      };

      const confirmMoveToQueue = async () => {
        try {
          await QueueService.moveUserToQueue(selectedUser,token);
          setUsers(users.filter(user => user.id !== selectedUser));
          setShowMoveToQueueModal(false);
        } catch (error) {
          console.error('Error moving user to queue:', error);
        }
        fetchAllUsers(business_id, business_user_id);
      };
    
    
      const handleSetCurrentQueue = (queueId, queueType) => {
        
        setCurrentQueue(queueId);
        setCurrentQueueType(queueType);
        localStorage.setItem('currentQueue', queueId);
        localStorage.setItem('currentQueueType', queueType);
      };
    
      useEffect(() => {
        let sortedUsers = [...users].filter(user => user.queue_id === currentQueue);
        
        if (currentQueueType === 'reserved') {
          sortedUsers.sort((a, b) => {
            return new Date(a.estimated_arrival_time) - new Date(b.estimated_arrival_time);
          });
        }
        
        setFilteredUsers(sortedUsers);
      }, [users, currentQueue, currentQueueType]);
    
    
      const vacantTableNames = tables && tables.length > 0
      ? tables
          .filter(table => table.status === 'vacant') // Filter tables with status 'vacant'
          .map(table => table.name)                   // Map to table names
          .join(', ')                                // Join them into a comma-separated string
      : '';                           // Handle the case when tables is null or empty
    
    
    
      const handleFormSubmit = async (user) => {
        const userPayload = {
          ...user,
          business_id,
        };
        if (currentUser) {
          try {
            const userPayload = {
              ...user,
              business_id,
              customer_id: currentUser.customer_id
            };
            const updatedUser = await QueueService.EditUser(userPayload, token);
            setUsers(users.map(u => (u.id === currentUser.id ? updatedUser : u)));
          } catch (error) {
            console.error('Error editing user:', error);
          }
        } else {
          try {
            const newUser = await QueueService.AddUser(userPayload, token);
            setUsers([...users, { id: users.length + 1, ...newUser }]);
          } catch (error) {
            console.error('Error adding user:', error);
          }
        }
    
        setShowForm(false);
        fetchAllUsers(business_id, business_user_id);
        fetchBusinessInfo(business_id);
      };
      

      const handleSave = async (user, new_wait_time) => {
      
        // Only make the API call if the wait time has changed
        if (new_wait_time !== user.initial_waiting_time) {
          const updated_user = {
            // ...user,
            id: user.id,
            initial_waiting_time: Number(new_wait_time),
            // name: user.customer_name,
            // phone: user.phone_number
          }
          try {
            // Call the API to update the user details with the new waiting time
            const userPayload = {
              ...updated_user,
              business_id,
              customer_id: user.customer_id,
              no_of_people: user.no_of_people,
              queue_id: user.queue_id
            };
            const updatedUser = await QueueService.EditUser(userPayload, token);
            setShowWaitingTimeEdit(false);
            setCurrentUser(null);
            fetchAllUsers(business_id, business_user_id);
      
            // Optional: handle the response from the API
            console.log('User updated successfully:', updatedUser);
          } catch (error) {
            // Handle API errors
            console.error('Error updating user:', error);
          }
        }
      };
      
    
      // Function to start editing the cell
      const handleEditClick = (user) => {
        setShowWaitingTimeEdit(true);
        setCurrentUser(user);
      };
      
      const handleApproveReservation = (userId,customer_name) => {
        setSelectedUserForApproval(userId);
        setShowApproveReservationModal(true);
        setCustomerName(customer_name);
        setOpenDropdownIndex(null);
      };

      const confirmApproveReservation = async () => {
        try {
          await QueueService.markUserReservationConfirmed(selectedUserForApproval, token);
          setUsers(users.filter(user => user.id !== selectedUserForApproval));
          setShowApproveReservationModal(false);
        } catch (error) {
          console.error('Error approving reservation:', error);
        }
        fetchAllUsers(business_id, business_user_id);
        fetchBusinessInfo(business_id);
      };
    
      

      const handleRejectReservation = (userId,customer_name) => {
        setSelectedUserForApproval(userId);
        setShowRejectReservationModal(true);
        setCustomerName(customer_name);
        setOpenDropdownIndex(null);
      };

      const confirmRejectReservation = async () => {
        try {
          await QueueService.markUserReservationRejected(selectedUserForApproval, token);
          setUsers(users.filter(user => user.id !== selectedUserForApproval));
          setShowRejectReservationModal(false);
        } catch (error) {
          console.error('Error rejecting reservation:', error);
        }
        fetchAllUsers(business_id, business_user_id);
        fetchBusinessInfo(business_id);
      };

      const handleOpenChat = (user) => {
        setCurrentUser(user);
        setShowChatModal(true);
      };
    
      const handleMessageSent = () => {
        fetchAllUsers(business_id, business_user_id);
      };


    return(
        <div className="queue-manager">
          {tables && (
            <div className="vacant-tables">
            <p>Vacant Tables: {vacantTableNames}</p>
            </div>
          )}
                {showForm && ( <Modal onClose={() => setShowForm(false)}> <UserForm onSubmit={handleFormSubmit} allentered_check={false} initialData={currentUser} queue_info={queue_info} auto_queue={queue_auto}usercheck={false} isPhoneRequired={false} business_id={business_id} tables={tables} business_type={business_type}/> </Modal> )}
                {showDeleteModal && ( <ConfirmDeleteModal onClose={() => setShowDeleteModal(false)} onConfirm={confirmDeleteUser} userType="admin" customer_name={customer_name}/> )}
                {showMoveToQueueModal && ( <MoveToQueueModal onClose={() => setShowMoveToQueueModal(false)} onConfirm={confirmMoveToQueue} userType="admin" customer_name={customer_name}/> )}
                {showApproveReservationModal && ( <ApproveReservationModal onClose={() => setShowApproveReservationModal(false)} onConfirm={confirmApproveReservation} userId={selectedUserForApproval} customer_name={customer_name} /> )}
                {showRejectReservationModal && ( <RejectReservationModal onClose={() => setShowRejectReservationModal(false)} onConfirm={confirmRejectReservation} userId={selectedUserForApproval} customer_name={customer_name} /> )}
                {showEntryModal && ( <ConfirmEntryModal onClose={() => setShowEntryModal(false)} onConfirm={confirmUserEntry} token={token} customer_name={customer_name} tables={tables}/> )}
                {showWaitingTimeEdit && ( <WaitingTimeEdit onClose={() => setShowWaitingTimeEdit(false)} onConfirm={handleSave} user={currentUser}/> )}
                {showRemarkModal && ( <AddRemarkModal onClose={() => setShowRemarkModal(false)} onConfirm={confirmAddRemark} /> )}
                  
                {showCallModal.isOpen && (
                  <CallModal
                    onClose={() => setCallModal({ isOpen: false, userId: null, userName: null})}
                    onConfirm={() => confirmCall(showCallModal.userId, showCallModal.userName)}
                    customer_name={showCallModal.userName}
                  />
                )}
                {showAICallModal.isOpen && (
                  <AICallModal
                    onClose={() => setAICallModal({ isOpen: false, phoneNumber: null, userName: null, mapping_id: null})}
                    onConfirm={() => AiCall(showAICallModal.phoneNumber, showAICallModal.userName, showAICallModal.mapping_id )}
                    customer_name={showAICallModal.userName}
                  />
                )}
                {message && ( <div className={`message-box ${message.type}`}> {message.text} </div> )}
                {TablesModal && ( <BusinessTablesModal onClose={() => setTablesModal(false)} /> )}
              {showCustomerHistoryModal && ( <CustomerHistoryModal customerId={selectedCustomerId} token={token} onClose={() => setShowCustomerHistoryModal(false)} /> )}
              {showChatModal && currentUser && ( <Modal onClose={() => setShowChatModal(false)}> <WhatsAppChatModal messages={currentUser.whatsapp_messages || []} phone_number={currentUser.phone_number} token={token} onClose={() => setShowChatModal(false)} onMessageSent={handleMessageSent} /> </Modal> )}
          
          
              <div id="notification-panel" class="notification-panel"></div>
          
            {queue_info.length > 0 && (
            <div className="queues">
              {queue_info.map((queue, index) => (
                <div key={index} className="queue-container">
                   <div className={`queue-header ${queue.queue_type === 'reserved' ? 'reserved' : ''}`}>
                    {queue.queue_type !== 'reserved' && (
                      <>
                        <div
                          onClick={() => handleNumberClick(queue.table_first_empty_time, queue.estimated_wait_time, queue.max_waiting_number, queue.min_time_diff_between_entries, queue.max_time_diff_between_entries, queue.queue_id)}
                          className="progress-container"
                        >
                          <div className="progress-circle">
                            <svg width="60" height="60" viewBox="0 0 36 36" className="circular-chart">
                              <path
                                className="circle-bg"
                                d="M18 2.0845
                                  a 15.9155 15.9155 0 0 1 0 31.831
                                  a 15.9155 15.9155 0 0 1 0 -31.831"
                              />
                              <path
                                className="circle"
                                strokeDasharray={`${Math.round((queue.total_initial_waiting_time / 100) * 100)}, 100`}
                                d="M18 2.0845
                                  a 15.9155 15.9155 0 0 1 0 31.831
                                  a 15.9155 15.9155 0 0 1 0 -31.831"
                              />
                              <text x="18" y="20.35" className="percentage">
                                {Number(queue.table_first_empty_time) + Number(queue.estimated_wait_time) * (Number(queue.max_waiting_number) - 1)} min
                              </text>
                            </svg>
                          </div>
                        </div>
                        <div
                          className={`toggle-switch ${queue.status === 'active' ? 'active' : 'inactive'}`}
                          onClick={() => handleToggleStatus(queue.queue_id, queue.status)}
                        >
                          <div className="toggle-knob"></div>
                        </div>
                      </>
                    )}
                  </div>
                  <button
                    className={`queue-button ${currentQueue === queue.queue_id ? 'active' : ''}`}
                    onClick={() => handleSetCurrentQueue(queue.queue_id, queue.queue_type)}
                  >
                    {queue.queue_name} ({users.filter(user => user.queue_id === queue.queue_id).length})
                  </button>
                </div>
              ))}
            </div>
          )}
          
                <table className="queue-table">
                <thead>
                    <tr>
                      <th>Name (Pax)</th>
                      <th>M. NO</th>
                      {currentQueueType === 'reserved' ? (
                        <th>Estimated Arrival Time</th>
                      ) : (
                        <th style={{ minWidth: '80px' }}>Arrival Time</th>
                      )}
                      {currentQueueType !== 'reserved' && (
                        <th style={{ minWidth: '50px' }}>Wait Time</th>
                      )}
                      {currentQueueType === 'reserved' && (
                        <th className="table-no-column">Table No.</th>
                      )}
                      {(currentQueueType === 'reserved' && !queue_auto) && (
                        <th className="table-no-column">Category</th>
                      )}
                      <th style={{ minWidth: '70px', textAlign: 'center' }}>Mark Entry</th>
                      <th>Actions</th>
                      <th>Remarks</th>
                      <th style={{ minWidth: '200px' }}>WhatsApp</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredUsers.map((user, index) => (
                      <tr key={user.id} className={user.reservation_status === 'pending' && user.queue_type === 'reserved' ? 'highlight-pending' : ''}>
                       
                       <td 
                      onClick={() => handleCustomerClick(user.customer_id)} 
                      style={{ 
                        cursor: 'pointer', 
                        position: 'relative',
                        padding: '12px 8px',  // Increased padding
                        whiteSpace: 'nowrap', // Prevent wrapping for the badge and start of the name
                        overflow: 'hidden',   // Hide overflow
                        textOverflow: 'ellipsis',
                        minWidth: '120px' // Add ellipsis for overflow text
                      }}
                    >
                      {user.badge && user.badge !== 'NA' && (
                        <span 
                          className={user.badge === 'gold' ? 'gold-badge' : 'silver-badge'} 
                          style={{ display: 'inline-block', verticalAlign: 'middle' }} // Ensure inline-block display
                        ></span>
                      )}
                      <span 
                        style={{ 
                          marginLeft: user.badge && user.badge !== 'NA' ? '5px' : '0',
                          display: 'inline', 
                          verticalAlign: 'middle',  // Align vertically with the badge
                          whiteSpace: 'normal',     // Allow wrapping for the name
                          overflow: 'visible'       // Ensure the name can wrap
                        }}
                      >
                        {user.customer_name} <b>({user.no_of_people})</b>
                      </span>
                    </td>
                        <td>
                          <a href={`tel:${user.phone_number}`} className="phone-link">
                            {user.phone_number}
                          </a>
                          {user.calls && user.calls.length > 0 && (
                          <div className="call-info" style={{ marginTop: '4px' }}>
                            {user.calls.map((call, index) => (
                              <div key={index} className="call-details">
                                <div>
                                  {formatDateTime(call.created_at)}{' '}
                                  {call.answer_time !== null ? `${call.answer_time}s` : ''}
                                </div>
                                <div>
                                  {/* Display icons based on the call status */}
                                  {call.status === "ANSWERED" ? (
                                    <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} />
                                  ) : call.status === "FAILED" ? (
                                    <FontAwesomeIcon icon={faTimesCircle} style={{ color: 'red' }} />
                                  ) : call.status === null ? (
                                    'calling...'
                                  ) : (
                                    call.status
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                        </td>


                        
                        <td
                          onClick={
                            currentQueueType === 'reserved' || 
                            (user.calls?.length > 0 && user.table_ready && user.calls[0].answer_time > 15) ? 
                            null : 
                            () => makeIVRCall(user.id, user.customer_name)
                          }
                          style={{
                            cursor: 
                              currentQueueType === 'reserved' || 
                              (user.calls?.length > 0 && user.table_ready && user.calls[0].answer_time > 15) ? 
                              'default' : 
                              'pointer',
                          }}
                        >
                          <>
                            {currentQueueType === 'reserved'
                              ? formatDateTime(user.estimated_arrival_time)
                              : formatDateTime(user.created_at)}
                            
                            {/* Render the call icon only if the queue is not 'reserved' */}
                            {currentQueueType !== 'reserved' && 
                            !(user.calls?.length > 0 && user.table_ready && user.calls[0].answer_time > 15) && 
                            <FaPhoneAlt className="call-icon" />
                          }
                            
                            {/* Display responses from user.calls if available */}
                            {user.calls.map((call, index) =>
                              call.user_response === '1' ? (
                                <span key={index} style={{ color: 'blue', fontSize: '10px', marginLeft: '2px' }}>Coming</span>
                              ) : call.user_response === '2' ? (
                                <span key={index} style={{ color: 'red', fontSize: '10px', marginLeft: '2px' }}>Not Coming</span>
                              ) : null
                            )}
                          </>
                        </td>

                              {currentQueueType !== 'reserved' && (
                                <td onClick={() => handleEditClick(user)}>
                                  {user.table_ready ? (
                                    // If table_ready is true, show "INVITED" in green
                                    <span style={{ color: 'green' }}>INVITED</span>
                                  ) : (
                                    <span>
                                      {/* Display the waiting time text */}
                                      {user.estimated_wait_time_buffer === 0
                                        ? `${user.initial_waiting_time} mins`
                                        : `${user.initial_waiting_time}-${user.initial_waiting_time + user.estimated_wait_time_buffer} mins`}
                                      {/* Add the edit icon next to the waiting time */}
                                      <FaEdit 
                                        style={{ marginLeft: '8px', cursor: 'pointer', color: '#888' }} // Add some styling
                                      />
                                    </span>
                                  )}
                                </td>
                              )}


                             {currentQueueType === 'reserved' && (
                              <td>
                               {user.table_no}
                              </td>
                             )}

                          {(currentQueueType === 'reserved' && !queue_auto) && (
                              <td>
                               {user.next_queue_name}
                              </td>
                             )}
           
                        <td onClick={() => handleUserEntry(user.id, user.customer_name)} style={{ textAlign: 'center' }}>
                          {currentQueueType === 'reserved' ? (index + 1) : user.waiting_number}
                          <FaArrowRight
                            className="mark-entry-icon"
                            style={{ cursor: 'pointer', marginLeft: '8px', color: "#007bff" }}
                          />
                        </td>
                        <td>
                          <button
                            className="action-button"
                            onClick={() => toggleDropdown(index)}
                          >
                            <FaEllipsisV />
                          </button>
                          
                          <div className="dropdown" style={{ position: 'relative' }}>
                            {openDropdownIndex === index && (
                              <div className="dropdown-menu" ref={dropdownRef} style={{ position: 'absolute', right: 0 }}>
                                <button onClick={() => handleEditUser(user)}>Edit</button>
                                <button onClick={() => add_note(user.id)}>Add Remark</button>
                                <button onClick={() => window.location.href = `https://wa.me/91${user.phone_number}`}> WhatsApp </button>
                                {currentQueueType === 'reserved' && (
                                  <>
                                    <button onClick={() => handleMoveToQueue(user.id,user.customer_name)}>Move to Queue</button>
                                    {user.reservation_status === 'pending' && (
                                      <>
                                        <button onClick={() => handleApproveReservation(user.id,user.customer_name)} style={{ color: 'green' }}>Approve Reservation</button>
                                        <button onClick={() => handleRejectReservation(user.id,user.customer_name)} style={{ color: '#FF4000' }}>Reject Reservation</button>
                                      </>
                                    )}
                                  </>
                                )}
                                {(business_id === 18 || business_id === 52) && <button onClick={() => handleAiCall(user.customer_name, user.phone_number, user.id)}>AI Call</button>}
                                <button onClick={() => handleDeleteUser(user.id, user.customer_name)}>Delete</button>
                                
                              </div>
                            )}
                          </div>
                        </td>
                        <td>
                    {user.notes && user.notes.length > 0 && (
                    <>
                      {formatNoteTime(user.notes[0].note_created_at)}: {user.notes[0].note_text}
                    </>
                  )}

                        </td>
                        <td onClick={() => handleOpenChat(user)} style={{ cursor: 'pointer' }}>
                        {user.whatsapp_messages && user.whatsapp_messages.length > 0 && (() => {
                          const lastMessage =
                            user.whatsapp_messages[user.whatsapp_messages.length - 1];
                          const { message_text = '', sent_by_system, timestamp } = lastMessage || {};

                          // Truncate if the message is longer than 50 characters
                          const MAX_LENGTH = 50;
                          const displayedText =
                            message_text.length > MAX_LENGTH
                              ? message_text.substring(0, MAX_LENGTH) + '...'
                              : message_text;

                          return (
                            <div style={{ fontSize: '0.9em' }}>
                              <span style={{ color: sent_by_system ? 'green' : 'blue' }}>
                                {displayedText}
                              </span>
                              <div style={{ fontSize: '0.8em', color: '#666', marginTop: '2px' }}>
                                {formatDateTime(timestamp)}
                              </div>
                            </div>
                          );
                        })()}
                      </td>

                      </tr>
                    ))}
                  </tbody>
                </table>
          
                <button onClick={handleAddUser} className="add-button">
                  <FaPlus size={20} />
                </button>
                {showNumberModal && (
                  <Modal onClose={() => setShowNumberModal(false)}>
                    <NumberModal
                     first_table_vacant_time={modalData.first_table_vacant_time} // A
                     average_waiting_time={modalData.average_waiting_time}
                     max_waiting_number= {modalData.max_waiting_number} 
                     min_time_diff_between_entries= {modalData.min_time_diff_between_entries || 0}
                     max_time_diff_between_entries= {modalData.max_time_diff_between_entries || 100}
                     token={token}
                     queue_id={modalData.queue_id}
                     handleNumberSubmit={handleNumberSubmit}
                     onClose={() => setShowNumberModal(false)}
                    />
                  </Modal>
                )}
              </div>
            );
}

export default QueueTable;
