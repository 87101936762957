import React, { useState, useEffect } from 'react';
import './CustomerHistoryModal.css';
import QueueService from './QueueService';

const CustomerHistoryModal = ({ customerId, token, onClose }) => {
  const [customerDetails, setCustomerDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedVisit, setSelectedVisit] = useState(null);

  const getDaysAgo = (dateString) => {
    const visitDate = new Date(dateString);
    const today = new Date(); 
    const diffTime = Math.abs(today - visitDate);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const getMealType = (dateString) => {
    const visitDate = new Date(dateString);
    const istOffset = 5.5 * 60 * 60 * 1000; 
    const hours = new Date(visitDate.getTime() + istOffset).getHours();
    // Lunch if between 6:00 and 18:29 in IST, otherwise Dinner
    return hours >= 6 && hours < 18.5 ? 'Lunch' : 'Dinner';
  };

  const handleClickOutside = (e) => {
    if (e.target.classList.contains('modal-overlay')) {
      onClose();
    }
  };

  const handleDateClick = (visitId) => {
    setSelectedVisit(selectedVisit === visitId ? null : visitId);
  };

  useEffect(() => {
    const fetchCustomerDetails = async () => {
      try {
        const details = await QueueService.getCustomerHistory(customerId, token);
        setCustomerDetails(details);
      } catch (error) {
        console.error('Error fetching customer details:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchCustomerDetails();
  }, [customerId, token]);

  if (loading) {
    return (
      <div className="modal-overlay" onClick={handleClickOutside}>
        <div className="modal">
          <div className="modal-content-history">
            <div>Loading...</div>
          </div>
        </div>
      </div>
    );
  }

  if (!customerDetails) {
    return (
      <div className="modal-overlay" onClick={handleClickOutside}>
        <div className="modal">
          <div className="modal-content-history">
            <div>No customer details found</div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="modal-overlay" onClick={handleClickOutside}>
      <div className="modal">
        <div className="modal-header">
          <p>
            Customer History ({customerDetails?.length || 0} Visits)
          </p>
          <button className="close-button" onClick={onClose}>
            
          </button>
        </div>
        
        {/* Current Points Balance Display */}
        <div className="points-balance-container">
          <div className="points-balance">
            <span className="points-label">Current Points Balance:</span>
            <span className="points-value">
              {customerDetails.reduce((total, visit) => 
                total + (visit.loyalty_points_earned || 0) - (visit.loyalty_points_redeemed || 0), 0)
              }
            </span>
          </div>
        </div>
        
        <div className="modal-content-history">
          <div className="visit-history">
            {customerDetails.map((visit) => {
              const daysAgo = getDaysAgo(visit.created_at);
              const isSelected = selectedVisit === visit.id;
              const createdDateIST = new Date(
                new Date(visit.created_at).getTime() + 5.5 * 60 * 60 * 1000
              );
              return (
                <div key={visit.id} className="visit-row">
                  <div
                    className="days-ago"
                    onClick={() => handleDateClick(visit.id)}
                    style={{ 
                      display: 'flex', 
                      justifyContent: 'space-between', 
                      alignItems: 'center' 
                    }}
                  >
                    <span>
                      {daysAgo === 0
                        ? 'Today'
                        : daysAgo === 1
                        ? 'Yesterday'
                        : `${daysAgo} days ago`}{' '}
                      | {getMealType(visit.created_at)}
                    </span>
                    {isSelected && (
                      <span className="exact-date-time">
                        {createdDateIST.toLocaleString('en-IN', {
                          day: 'numeric',
                          month: 'short',
                          year: '2-digit',
                          hour: 'numeric',
                          minute: 'numeric',
                          hour12: true,
                        })}
                      </span>
                    )}
                  </div>
                  <hr className="gray-line" />
                  <div className="history-card-details">
                    <div className="2nd-line">
                      <span className="visit-name">
                        <i
                          className="fas fa-map-marker-alt"
                          style={{
                            fontSize: '11px',
                            color: '#4B4B4B',
                            marginRight: '4px',
                          }}
                        ></i>
                        {visit.name
                          ? visit.name.length > 20
                            ? `${visit.name.substring(0, 17)}...`
                            : visit.name
                          : 'N/A'}
                      </span>
                    </div>

                    {/* Status badge */}
                    <div className={`status-indicator ${visit.status}`}>
                      {visit.status}
                    </div>
                  </div>

                  {/* Review section */}
                  <div className="review-container">
                    {/* Display a rating badge if rating is not null */}
                    {visit.review && visit.review.rating != null ? (
                      <div className={`rating-badge rating-${visit.review.rating}`}>
                        {visit.review.rating} 
                        <i className="fas fa-star" style={{ marginLeft: '3px' }}></i>
                      </div>
                    ) : (
                      <div className="rating-badge no-rating">---</div>
                    )}

                    {/* Review text if available */}
                    {visit.review && visit.review.text ? (
                      <p className="review-text">{visit.review.text}</p>
                    ) : (
                      <p className="review-text no-review">No Review</p>
                    )}
                  </div>

                  {/* Loyalty and bill information section */}
                  <div className="loyalty-container">
                    <div className="loyalty-row">
                      <span className="loyalty-label">Points Earned:</span>
                      <span className="loyalty-value">{visit.loyalty_points_earned || 0}</span>
                    </div>
                    <div className="loyalty-row">
                      <span className="loyalty-label">Points Redeemed:</span>
                      <span className="loyalty-value">{visit.loyalty_points_redeemed || 0}</span>
                    </div>
                    <div className="loyalty-row">
                      <span className="loyalty-label">Bill Amount:</span>
                      <span className="loyalty-value">
                        ₹{visit.total_bill_amount ? visit.total_bill_amount.toFixed(2) : '0.00'}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="modal-footer">
          <button className="secondary-button" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomerHistoryModal;
