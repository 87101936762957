import React, { useState, useEffect, useRef } from 'react';
import QueueService from './QueueService';
import MarkdownParser from './MarkdownParser';

const ChatWindow = ({ chat, token, phone_number_id }) => {
  // 1) Define state and hooks at the top (unconditionally)
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const messagesEndRef = useRef(null);

  // 2) You can read chat-related data safely
  const phone = chat?.user_id || null;
  const canReply = chat?.status || false;

  // 3) Auto-scroll to bottom after each render (when messages change)
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  // 4) Fetch messages when phone changes (if phone is null, do nothing)
  useEffect(() => {
    if (!phone) {
      return; // phone is null => skip fetching
    }

    const fetchMessages = async () => {
      try {
        setLoading(true);
        const { messages: fetchedMessages } = await QueueService.FetchMessages(phone, phone_number_id);
        setMessages(fetchedMessages || []);
      } catch (err) {
        console.error('Error fetching messages:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchMessages();
  }, [phone]);

  // 5) Handle sending messages (only if canReply)
  const handleSendMessage = async () => {
    if (!phone || !newMessage.trim() || !canReply) {
      return;
    }

    try {
      const response = await QueueService.SendWhatsappMessage(`91${phone}`, newMessage, token, phone_number_id);
      if (response && response.status === 'Success') {
        // Add the new message to local state
        const newMsg = {
          id: Date.now(), // Temporary ID
          message_text: newMessage,
          sent_by_system: true,
          timestamp: new Date().toISOString(),
          entity_type: 'customer',
        };
        setMessages((prev) => [...prev, newMsg]);
        setNewMessage('');
      }
    } catch (err) {
      console.error('Error sending message:', err);
    }
  };

  // 6) Send on Enter (but no shift)
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  // 7) Format timestamps
  const formatTimestamp = (timeString) => {
    if (!timeString) return '';
    const rawDate = new Date(timeString);
    // Example offset for IST (UTC+5:30)
    const withOffset = new Date(rawDate.getTime() + (5 * 60 + 30) * 60000);
    return withOffset.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  };

  // 8) Render message content via MarkdownParser
  const renderMessageContent = (msg) => (
    <MarkdownParser text={msg.message_text} />
  );

  // 9) Conditionally render UI:
  // If "chat" is not provided, show placeholder;
  // otherwise, show chat UI.
  if (!chat) {
    return (
      <div className="chat-window-container">
        <div className="chat-window-header">No Chat Selected</div>
        <div className="chat-window-messages">
          <div className="chat-window-message-placeholder">
            Please select a chat from the list.
          </div>
        </div>
      </div>
    );
  }

  // Otherwise, render the normal chat window
  return (
    <div className="chat-window-container">
      <div className="chat-window-header">
        Chat with {chat.name || phone}
      </div>

      <div className="chat-messages">
        {loading && <div className="chat-window-loading">Loading messages...</div>}

        {messages.map((msg, index) => (
          <div
            key={msg.id || index}
            className={`message ${msg.sent_by_system ? 'system' : 'customer'}`}
          >
            <div className="message-content">
              {renderMessageContent(msg)}
              <span className="timestamp">{formatTimestamp(msg.timestamp)}</span>
            </div>
          </div>
        ))}

        <div ref={messagesEndRef} />
      </div>

      {!canReply && (
        <div style={{ textAlign: 'center', color: 'red', margin: '0.5rem' }}>
          This chat is inactive. You cannot send messages.
        </div>
      )}

      <div className="chat-window-input-container">
        <input
          type="text"
          placeholder="Type a message"
          className="chat-window-input"
          value={newMessage}
          onKeyPress={handleKeyPress}
          onChange={(e) => setNewMessage(e.target.value)}
          disabled={!canReply}
          style={{
            backgroundColor: canReply ? 'white' : '#f0f0f0',
            color: canReply ? 'black' : 'gray',
          }}
        />
        <button
          className="chat-window-send-button"
          onClick={handleSendMessage}
          disabled={!canReply}
          style={{
            backgroundColor: canReply ? '#007bff' : '#ccc',
            cursor: canReply ? 'pointer' : 'not-allowed',
          }}
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default ChatWindow;
