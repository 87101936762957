import React, { useEffect, useState, useRef, useCallback } from 'react';
import QueueService from './QueueService'; 
import MarkdownParser from './MarkdownParser';

const ChatList = ({ onChatClick, selectedChat, phone_number_id }) => {
  const [chats, setChats] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  
  // Pagination states for infinite scroll
  const [after, setAfter] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [filter, setFilter] = useState('all'); // 'all' | 'active' | 'inactive'
  const observerRef = useRef(null);

  // 1. Fetch chats (newest set) initially
  const fetchChats = useCallback(async (cursorAfter = null) => {
    try {
      setLoading(true);
      const data = await QueueService.FetchRecentUsersWithMessages(cursorAfter, phone_number_id, filter);
      /**
       *  Expecting structure:
       *  {
       *    messages: [...],
       *    after: "...",
       *    before: "...",
       *  }
       */
      if (data.messages && data.messages.length > 0) {
        setChats((prev) => [...prev, ...data.messages]);
      } else {
        setHasMore(false);
      }
      // Update the "after" cursor
      if (data.after) {
        setAfter(data.after);
      } else {
        setHasMore(false);
      }
    } catch (err) {
      console.error('Error fetching chats:', err);
      setError('Failed to load chats');
    } finally {
      setLoading(false);
    }
  }, [filter]);

  useEffect(() => {
    // Reset states when filter changes
    setChats([]);
    setAfter(null);
    setHasMore(true);
    fetchChats();
    // eslint-disable-next-line
  }, [filter]);

  // 2. Infinite scroll: watch sentinel
  useEffect(() => {
    if (loading || !hasMore) return;

    const observer = new IntersectionObserver(
      (entries) => {
        const [target] = entries;
        if (target.isIntersecting) {
          // Load next page
          fetchChats(after);
        }
      },
      { root: null, rootMargin: '0px', threshold: 0.1 }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [after, hasMore, loading, fetchChats]);

  // 3. Handle chat item click
  const handleChatItemClick = (chatItem) => {
    onChatClick(chatItem);
  };

  // 4. Format timestamp
  const formatTimestamp = (timeString) => {
    if (!timeString) return '';
    const rawDate = new Date(timeString);
    // Example for IST (UTC+5:30)
    const withOffset = new Date(rawDate.getTime() + (5 * 60 + 30) * 60000);
    
    // Get today's date at midnight for comparison
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    const messageDate = new Date(withOffset);
    messageDate.setHours(0, 0, 0, 0);
    
    const timeStr = withOffset.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });

    // Calculate difference in days
    const diffDays = Math.floor((today - messageDate) / (1000 * 60 * 60 * 24));
    
    // Format date based on how recent it is
    if (diffDays === 0) {
      return timeStr; // Just show time for today
    } else if (diffDays === 1) {
      return `Yesterday ${timeStr}`;
    } else if (diffDays > 1 && diffDays < 7) {
      // Show day name for messages within the last week
      return withOffset.toLocaleDateString('en-US', { weekday: 'long' });
    } else {
      // Show full date for older messages
      return withOffset.toLocaleDateString('en-US', {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
      });
    }
  };

  // Remove the frontend filtering logic
  const filteredChats = chats;

  if (error) {
    return <p style={{ margin: '1rem' }}>Error: {error}</p>;
  }

  return (
    <div className="chat-list-container">
      <div className="chat-list-header">Chats</div>

      {/* Filter Dropdown */}
      <div style={{ margin: '0.5rem' }}>
        <label>Show: </label>
        <select value={filter} onChange={(e) => setFilter(e.target.value)}>
          <option value="all">All</option>
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
        </select>
      </div>

      <ul className="chat-list">
        {filteredChats.map((chat) => (
          <li
            key={chat.user_id}
            className={`chat-list-item ${
              selectedChat?.user_id === chat.user_id ? 'selected-chat' : ''
            } ${chat.status ? '' : 'chat-inactive'}`}
            onClick={() => handleChatItemClick(chat)}
            style={{
              cursor: 'pointer',
              opacity: chat.status ? 1 : 0.5,
            }}
          >
            <p
              className="chat-user-id"
              style={{ fontWeight: 'bold', color: 'white' }}
            >
              {chat.name || chat.user_id}
            </p>
            <div style={{ color: 'lightgrey' }}>
              <MarkdownParser
                text={
                  chat.message_text.length > 50
                    ? `${chat.message_text.substring(0, 50)}...`
                    : chat.message_text
                }
              />
            </div>
            <span style={{ color: 'lightgrey', fontSize: '0.8rem' }}>
              {formatTimestamp(chat.timestamp)}
            </span>
          </li>
        ))}

        {hasMore && !loading && (
          <li
            ref={observerRef}
            style={{
              textAlign: 'center',
              padding: '1rem',
              color: 'grey',
            }}
          >
            Loading more...
          </li>
        )}
      </ul>

      {loading && <p style={{ margin: '1rem', textAlign: 'center' }}>Loading...</p>}
    </div>
  );
};

export default ChatList;
